<template>
  <a-form-model
    ref="iform"
    :model="formData"
    :rules="rules"
    @submit="handleSubmit"
    @submit.native.prevent
  >
    <a-form-model-item prop="mobile" has-feedback>
      <a-input
        v-model="formData.mobile"
        type="number"
        placeholder="请输入您的手机号码"
        size="large"
        @blur="changeFrom"
      >
        <a-icon type="mobile" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="password">
      <a-input-password
        v-model="formData.password"
        type="password"
        placeholder="请输入您的密码"
        size="large"
        @blur="changeFrom"
        ref="passwordInput"
      >
        <a-icon type="lock" slot="prefix" />
      </a-input-password>
    </a-form-model-item>
    <a-form-model-item class="page-vcode-item">
      <a-row :gutter="16">
        <a-col :span="14">
          <a-form-model-item prop="vcode">
            <a-input
              v-model="formData.vcode"
              size="large"
              type="text"
              placeholder="请输入验证码"
              @blur="changeFrom"
              ref="vcodeInput"
            >
              <a-icon slot="prefix" type="safety" />
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <div>
            <img :src="captcha.base64" @click="getCaptcha" />
          </div>
        </a-col>
      </a-row>
    </a-form-model-item>
    <a-form-model-item>
      <a-checkbox v-model="auto">7天内自动登录</a-checkbox>
      <router-link :to="{ name: 'forget' }" class="page-forget" replace
        >忘记密码</router-link
      >
    </a-form-model-item>
    <a-form-model-item>
      <a-button
        type="primary"
        size="large"
        html-type="submit"
        :disabled="!validateResult"
        :loading="!isEnable"
      >
        登录
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { login } from "@/utils/auth-utils"

export default {
  data() {
    return {
      isMounted: false, //页面是否初始化完成
      isChange: false, //表单是否有改动
      isEnable: true, //登录按钮是否启用
      auto: false, //是否7天自动登录
      timer: undefined, //验证码计时器
      captcha: {
        base64: undefined,
        key: undefined,
        md5: undefined
      },
      formData: {
        mobile: undefined,
        password: undefined,
        vcode: undefined
      },
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur"
          },
          {
            pattern: /^1[23456789]\d{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入您的密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "密码最少6位",
            trigger: "blur"
          }
        ],
        vcode: [
          {
            required: true,
            message: "请输入您的验证码",
            trigger: "blur"
          },
          {
            pattern: /^[A-Za-z0-9]{4}$/,
            message: "请输入有效的验证码",
            trigger: "blur"
          }
        ]
      }
    }
  },
  created() {
    this.getCaptcha()
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    changeFrom() {
      if (!this.isChange) {
        this.isChange = true
      }
    },
    getCaptcha() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.$request({
        url: "/getCaptcha"
      }).then(res => {
        this.captcha = res.data
      })
      this.timer = setTimeout(this.getCaptcha, 300000)
    },
    handleSubmit() {
      if (!this.captcha.key) {
        this.$notification.error({
          message: "登录失败",
          description: "请刷新页面重置二维码"
        })
        return
      }
      this.$refs.iform.validate(valid => {
        if (valid) {
          this.isEnable = false
          this.getCaptcha()
          const password = this.$md5(this.formData.password)
          this.$request({
            url: "/login",
            method: "post",
            data: {
              mobile: this.formData.mobile,
              password: password,
              vcode: this.formData.vcode,
              key: this.captcha.key,
              auto: this.auto
            }
          })
            .then(res => {
              if (res.data.token) {
                this.$message.success(res.message)
                login(res.data, this.auto)
                setTimeout(() => {
                  this.$router.replace("/dashboard")
                }, 1000)
              } else {
                this.$message.error(res.message)
                this.formData.password = ""
                this.formData.vcode = ""
                this.$refs.passwordInput.focus()
                this.isEnable = true
              }
            })
            .catch(err => {
              if (err.code == 400) {
                this.formData.vcode = ""
                this.$refs.vcodeInput.focus()
              }
              this.isEnable = true
            })
        }
      })
    }
  },
  computed: {
    validateResult: function() {
      let result = false
      if (this.isMounted && this.isChange && this.isEnable) {
        this.$refs.iform.validate(valid => {
          result = valid
        })
      }
      return result
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 15px;
}

.page-vcode-item {
  margin-bottom: 0px;
}

.page-vcode-item img {
  width: 100%;
  cursor: pointer;
}

.page-forget {
  float: right;
}

.ant-form-item button {
  width: 100%;
}
</style>

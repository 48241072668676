<template>
  <a-form-model
    ref="iform"
    :model="formData"
    :rules="rules"
    @submit="handleSubmit"
    @submit.native.prevent
  >
    <h3>密码找回</h3>
    <a-form-model-item prop="mobile" has-feedback>
      <a-input
        v-model="formData.mobile"
        placeholder="请输入您的手机号码"
        size="large"
        type="number"
      >
        <a-icon type="mobile" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item class="page-vcode-item">
      <a-row :gutter="16">
        <a-col :span="16">
          <a-form-model-item prop="sms_code">
            <a-input
              v-model="formData.sms_code"
              size="large"
              type="number"
              placeholder="请输入验证码"
              ref="smsCodeInput"
            >
              <a-icon slot="prefix" type="mail" />
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-button
            size="large"
            @click="sendSMS"
            :disabled="!sendButton"
            v-text="(sendButton && '获取验证码') || time + ' s'"
          ></a-button>
        </a-col>
      </a-row>
    </a-form-model-item>
    <a-form-model-item prop="password">
      <a-input-password
        v-model="formData.password"
        type="password"
        placeholder="请输入您的新密码"
        size="large"
      >
        <a-icon type="lock" slot="prefix" />
      </a-input-password>
    </a-form-model-item>

    <a-form-model-item>
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="16">
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            :disabled="!changeButton"
            :loading="!changeButton"
          >
            修改密码
          </a-button>
        </a-col>
        <a-col class="gutter-row" :span="8">
          <router-link :to="{ name: 'login' }" replace>返回登录</router-link>
        </a-col>
      </a-row>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
export default {
  data() {
    return {
      sendButton: true,
      changeButton: true,
      time: 60,
      formData: {
        mobile: undefined,
        sms_code: undefined,
        password: undefined
      },
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur"
          },
          {
            pattern: /^1[23456789]\d{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur"
          }
        ],
        sms_code: [
          {
            required: true,
            message: "请输入您的短信验证码",
            trigger: "blur"
          },
          {
            pattern: /^\d{6}$/,
            message: "请输入有效的短信验证码",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入您的新密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "密码最少6位",
            trigger: "blur"
          },
          {
            max: 30,
            message: "密码不超过30位",
            trigger: "blur"
          }
        ]
      }
    }
  },
  mounted() {
    if (this.$cookies.get("sendSmsTime")) {
      this.time =
        60 -
        parseInt(
          (new Date().getTime() - this.$cookies.get("sendSmsTime")) / 1000
        )
      this.sendButton = false
      this.formData.mobile = this.$cookies.get("mobile")
      const interval = window.setInterval(() => {
        if (this.time-- <= 0) {
          this.time = 60
          this.sendButton = true
          window.clearInterval(interval)
        }
      }, 1000)
    }
  },
  methods: {
    sendSMS(e) {
      e.preventDefault()
      this.$refs.iform.validateField("mobile", errorMessage => {
        if (!errorMessage) {
          this.sendButton = false
          this.$request({
            url: "/sendSMS",
            method: "post",
            data: {
              mobile: this.formData.mobile
            }
          })
            .then(res => {
              this.formData.sms_code = ""
              this.$refs.smsCodeInput.focus()
              this.$message.success(res.message)
              this.$cookies.set("mobile", this.formData.mobile, "60s")
              this.$cookies.set("sendSmsTime", new Date().getTime(), "60s")
              const interval = window.setInterval(() => {
                if (this.time-- <= 0) {
                  this.time = 60
                  this.sendButton = true
                  window.clearInterval(interval)
                }
              }, 1000)
            })
            .catch(() => {
              this.sendButton = true
            })
        }
      })
    },
    handleSubmit() {
      this.$refs.iform.validate(valid => {
        if (valid) {
          let password = this.$md5(this.formData.password)
          this.changeButton = false
          this.$request({
            url: "/changePassword",
            method: "post",
            data: {
              mobile: this.formData.mobile,
              sms_code: this.formData.sms_code,
              password: password
            }
          })
            .then(res => {
              this.$message.success(res.message)
              this.$cookies.remove("mobile")
              setTimeout(() => {
                this.$router.replace("/user/login")
              }, 2500)
            })
            .catch(() => {
              this.formData.sms_code = ""
              this.formData.password = ""
              this.$refs.smsCodeInput.focus()
              this.changeButton = true
            })
        }
      })
    }
  }
}
</script>

<style scoped>
h3 {
  margin-bottom: 15px;
  text-align: center;
}

.ant-form-item {
  margin-bottom: 15px;
}

.page-vcode-item {
  margin-bottom: 0px;
}

.page-vcode-item button {
  font-size: 14px;
}

.ant-form-item a {
  float: right;
}

.ant-form-item button {
  width: 100%;
}
</style>
